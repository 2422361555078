
import { defineComponent, ref } from "vue";
import RequestCardList from "@/components/common/Request/RequestCardList.vue";
import { RequestListType, RequestKindTypes } from "@/models/request";
import { RouterName } from "@/models/common";
import { useI18n } from "vue-i18n";
import OnlineCareIntro from "@/components/onlineCare/Intro.vue";
import router from "@/router";

export default defineComponent({
  name: "Online Care",
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const { t: l } = useI18n();
    const reqShow = ref(true);
    const resShow = ref(true);
    return {
      RequestListType,
      RequestKindTypes,
      RouterName,
      t,
      l,
      router,
      reqShow,
      resShow,
    };
  },
  components: {
    RequestCardList,
    OnlineCareIntro,
  },
});
