<i18n>
{
  "en": {
    "info" : "온라인상담 예약신청e",
    "desc" : "온라인대면 진료 및 처방전 발급e"
  },
  "ja": {
    "info" : "온라인상담 예약신청j",
    "desc" : "온라인대면 진료 및 처방전 발급j"
  },
  "ko":{
    "info" : "온라인상담 예약신청",
    "desc" : "온라인대면 진료 및 처방전 발급"
  }
}
</i18n>
<template>
  <!-- <Scroll /> -->
  <section class="intro-wrapped" v-scrollIcon>
    <OnlineCareIntro :isImage="false" />
  </section>
  <main class="main">
    <section class="main__section--wide">
      <section class="online-care-list card-list">
        <div class="list-header">
          <h3 class="-bold">
            신청내역조회
            <span @click="reqShow = !reqShow" class="-royalBlue">
              <i class="pi pi-chevron-down -bold" v-if="reqShow"> 접기</i>
              <i class="pi pi-chevron-up -bold" v-else> 열기</i>
            </span>
          </h3>
          <a class="-royalBlue" @click="() => router.go(0)">새로고침</a>
        </div>
        <RequestCardList
          :type="RequestListType.Request"
          :kind="RequestKindTypes.OnlineCare"
          :isShow="reqShow"
        />
        <div class="list-header">
          <h3 class="-bold">
            완료내역
            <span @click="resShow = !resShow" class="-royalBlue"
              ><i class="pi pi-chevron-down -bold" v-if="resShow"> 접기 </i>
              <i class="pi pi-chevron-up -bold" v-else> 열기</i></span
            >
          </h3>
          <a class="-royalBlue" @click="() => router.go(0)">새로고침</a>
        </div>
        <RequestCardList
          :type="RequestListType.Response"
          :kind="RequestKindTypes.OnlineCare"
          :isShow="resShow"
        />
      </section>
    </section>
  </main>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import RequestCardList from "@/components/common/Request/RequestCardList.vue";
import { RequestListType, RequestKindTypes } from "@/models/request";
import { RouterName } from "@/models/common";
import { useI18n } from "vue-i18n";
import OnlineCareIntro from "@/components/onlineCare/Intro.vue";
import router from "@/router";

export default defineComponent({
  name: "Online Care",
  setup() {
    const { t } = useI18n({ useScope: "global" });
    const { t: l } = useI18n();
    const reqShow = ref(true);
    const resShow = ref(true);
    return {
      RequestListType,
      RequestKindTypes,
      RouterName,
      t,
      l,
      router,
      reqShow,
      resShow,
    };
  },
  components: {
    RequestCardList,
    OnlineCareIntro,
  },
});
</script>
<style lang="scss" scoped>
@import "@/styles/mixin.scss";
</style>